#root {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}
