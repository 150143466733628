.form-wrap {
    width: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.form-wrap h1 {
    text-align: center;
}