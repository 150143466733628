.global-header {
    position: relative;
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
}

.menu-trigger {
    height: 64px;
    padding: 0 24px;
    font-size: 20px;
    cursor: pointer;
    transition: all .3s, padding 0s;
}

.logout {
    float: right;
    height: 64px;
    margin-left: auto;
    padding: 0 24px;
    cursor: pointer;
}